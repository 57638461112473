$(document).ready(function () {
  /*** Header fixed ***/
  $(window).scroll(function () {
      var scrollPixel = $(window).scrollTop();
      if (scrollPixel < 50) {
        $('header').removeClass('headerFix');
      } else {
        $('header').addClass('headerFix');
      };
  });
  new WOW().init();
  $('.navbar-toggler').click(function () {
    $('body').toggleClass('open-menu');
  });
});
wow = new WOW(
    {
    boxClass:     'wow',      // default
    animateClass: 'animated', // default
    offset:       0,          // default
    mobile:       true,       // default
    live:         true        // default
  }
)

document.addEventListener("DOMContentLoaded", function () {
  const options = { root: null, rootMargin: "0px", threshold: 0.2 };
  // IMAGE ANIMATION
  let revealCallback = (entries) => {
    entries.forEach((entry) => {
    let container = entry.target;
    if (entry.isIntersecting) {
      container.classList.add("animating");
      return;
    }
    if (entry.boundingClientRect.top > 0) {
      container.classList.remove("animating");
    }
    });
  };
  let revealObserver = new IntersectionObserver(revealCallback, options);
  document.querySelectorAll(".reveal-effect").forEach((reveal) => {
    revealObserver.observe(reveal);
  });
});